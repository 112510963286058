import jwtDecode from 'jwt-decode'

export default class SessionHandler {
  constructor(id, endpoint, debugMode) {
    this.id = id
    this.endpoint = endpoint
    this.debugMode = false || debugMode
  }

  get apiTokenStorageKey() { return (`${this.endpoint + this.id}-api-token`) }

  get expiry() {
    const expiry = sessionStorage.getItem(this.endpoint + this.id)
    if (this.debugMode) {
      console.log('[SessionHandler] Expiry is set to', expiry && new Date(expiry * 1000)) // eslint-disable-line no-console
    }
    return expiry
  }

  set expiry(timeoutInMinutes) {
    const now = Math.round(new Date().getTime() / 1000)
    const ExpiryTimestamp = now + (timeoutInMinutes * 60)
    sessionStorage.setItem(this.endpoint + this.id, ExpiryTimestamp)

    if (this.debugMode) {
      console.log('[SessionHandler] Set expiry to', new Date(ExpiryTimestamp * 1000)) // eslint-disable-line no-console
    }
  }

  get tokenIsExpired() {
    const now = Math.round(new Date().getTime() / 1000)
    const { expiry } = this
    const tokenIsExpired = (window === window.parent) && window === window.top && expiry && now > expiry
    if (this.debugMode) {
      console.log('[SessionHandler] Token is expired', !!tokenIsExpired) // eslint-disable-line no-console
    }
    return !!tokenIsExpired
  }

  get tokenIsExpiredOrNull() {
    return this.tokenIsExpired || this.expiry === null
  }

  // eslint-disable-next-line class-methods-use-this
  get loggedInUser() {
    if (window.msalInstance && window.msalInstance.getAllAccounts() && window.msalInstance.getAllAccounts().length > 0) {
      return window.msalInstance.getAllAccounts()[0]
    }
    return null
  }

  get userRoles() {
    const token = this.getApiTokenFromSession()
    const decodedAccessToken = jwtDecode(token)
    if (this.debugMode) {
      console.log('[SessionHandler] Decoded Access Token: ', decodedAccessToken) // eslint-disable-line no-console
    }
    return decodedAccessToken && decodedAccessToken.roles
  }

  resetExpiry() {
    sessionStorage.removeItem(this.endpoint + this.id)

    if (this.debugMode) {
      console.log('[SessionHandler] Remove from storage') // eslint-disable-line no-console
    }
  }

  checkTokenIsExpiredAndLogout() {
    if (this.tokenIsExpiredOrNull) { // or null means the user had an other tab open which triggered this timeout already
      this.resetExpiryAndLogout()
    }
  }

  resetExpiryAndLogout() {
    const accounts = this.loggedInUser
    const logoutRequest = {
      account: accounts,
      postLogoutRedirectUri: window.location.origin,
    }
    window.msalInstance.logout(logoutRequest)
  }

  storeToken(token) {
    window.sessionStorage.setItem(this.apiTokenStorageKey, token)
  }

  getApiTokenFromSession() {
    return window.sessionStorage.getItem(this.apiTokenStorageKey)
  }
}
